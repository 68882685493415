
import { defineComponent, computed, onMounted, ref } from "vue";
import { autoLogin } from "@/core/services/AutoLogin";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "kt-user-avatar",
  props: {
    avatar: { type: String, default: "" },
    size: { type: String, default: "45px" },
    id: { type: Number, default: null },
  },
  setup() {
    const store = useStore();
    // const userType = computed(() => {
    //   return store.getters.currentUser.type;
    // });
    const route = useRoute();
    const permission = ref();
    const userType = ref();

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;
      }, 3000);
    });

    return {
      autoLogin,
      userType,
      permission,
      route,
    };
  },
});
