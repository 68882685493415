import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.userType.includes('admin') &&
      _ctx.permission?.includes('auto_login') &&
      _ctx.route.name !== 'adminsList'
        ? _ctx.autoLogin(_ctx.id)
        : ''
    )),
    class: _normalizeClass(["symbol symbol-circle me-2", `symbol-${_ctx.size}`])
  }, [
    _createElementVNode("img", {
      src: _ctx.avatar,
      class: ""
    }, null, 8, _hoisted_1)
  ], 2))
}