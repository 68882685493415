
import { defineComponent, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { statusColor, translate } from "@/core/services/HelpersFun";
import { hideModal } from "@/core/helpers/dom";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "transaction-details",
  emits: ["refresh"],
  props: {
    transaction: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const price = ref(parseInt(props.transaction?.amount).toLocaleString());
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const submitButtonRef2 = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);

    watch(
      () => props.transaction,
      () => {
        price.value = parseInt(props.transaction?.amount).toLocaleString() ;
      }
    );

    // ok
    const submit = (e) => {
      e.preventDefault;
      if (!submitButtonRef.value || !price.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      // send ok
      ApiService.setHeader();
      ApiService.post("/admin/transaction/cash/confirm", {
        transaction_id: props.transaction?.id,
        amount: price.value.replaceAll(',', '') || 0,
      })
        .then(() => {
          hideModal(newAddressModalRef.value);
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          emit("refresh");
        })
        .catch(({ response }) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const submitReject = (e) => {
      e.preventDefault;
      // send ok

      if (!submitButtonRef2.value || !price.value) {
        return;
      }
      //Disable button
      submitButtonRef2.value.disabled = true;
      // Activate indicator
      submitButtonRef2.value.setAttribute("data-kt-indicator", "on");

      ApiService.setHeader();
      ApiService.post("/admin/transaction/cash/reject", {
        transaction_id: props.transaction?.id,
        amount: price.value,
      })
        .then(() => {
          hideModal(newAddressModalRef.value);
          if (submitButtonRef2.value) {
            submitButtonRef2.value.disabled = false;
            submitButtonRef2.value?.removeAttribute("data-kt-indicator");
          }
          emit("refresh");
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
          if (submitButtonRef2.value) {
            submitButtonRef2.value.disabled = false;
            submitButtonRef2.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const permission = ref();

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
      }, 3000);
    });

    return {
      statusColor,
      translate,

      submit,
      submitReject,
      price,
      submitButtonRef,
      submitButtonRef2,
      newAddressModalRef,
      permission,
    };
  },
});
